import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Stepper from "./Stepper";

const Modal = ( { showModal } ) => {
    return (
        <>
            <div className={`${showModal ? 'w-full h-full justify-center items-center flex overflow-x-hidden overflow-y-auto inset-0 z-50 outline-none focus:outline-none fixed top-0 left-0' : 'static'}`}>
                <div className={`form-modal relative w-auto ${showModal ? 'p-2 my-6 ' : 'inner'} mx-auto max-w-6xl max-h-full`}>
                    <div className="border-0 shadow-lg relative flex flex-col w-full bg-white bg-opacity-80 transition outline-none focus:outline-none">
                        <header className="p-3 bg-white text-center">
                            <h1 className="h2 px-4">Ihre gratis Probefahrt in nur wenigen Klicks!</h1>
                            <h2 className="h5 hidden md:block">Jetzt ausfüllen und bis zu 3600€ sparen.</h2>
                        </header>
                        <div className="flex-auto">
                            <Stepper />
                        </div>
                    </div>

                    <div className="shield hidden md:block absolute right-1 -bottom-5">
                        <StaticImage
                            src={"../images/wappen.png"}
                            width={75}
                            height={99}
                            alt="Professionelle Beratung"
                            className={`shadow-lg`}
                        />
                    </div>
                </div>
            </div>
            <div className={`modal-bg h-full w-full opacity-90 inset-0 bg-black ${showModal ? 'block fixed top-0 left-0' : 'hidden'}`} />
        </>
    )
}

export default Modal;
